import { useAppSelector } from "@/store/store";
import { getTargetValue, isUrlDownloadUrl } from "@/utils/urlUtil";
import { createHref } from "@/utils/util";
import clsx from "clsx";
import dynamic from "next/dynamic";
import Link from "next/link";
import React, { ReactNode } from "react";
const Button = dynamic(() => import("@mui/material/Button"));
const CmsContentButton = dynamic(
  () => import("@/components/cms/cmsContent/cmsContentButton/cmsContentButton")
);

export interface PbContentButtonProps {
  type?: "button" | "submit" | "reset";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  url?: string;
  text: string;
  popoverPlacement?: "top" | "bottom" | "left" | "right";
  cmsPosition?: number;
  cmsFieldText?: string;
  disableEditView?: boolean;
  className?: string;
  popoverClassName?: string;
  cmsPopoverOpenerId?: string;
  getParams?: string;
  hrefLang?: string;
  rel?: string;
  target?: string;
  anchorName?: string;
  cmsFieldGetParams?: string;
  cmsFieldRel?: string;
  cmsFieldTarget?: string;
  cmsFieldHrefLang?: string;
  cmsFieldUrl?: string;
  cmsFieldAnchorName?: string;
  disabled?: boolean;
  startIcon?: ReactNode;
}

/**
 * Component for updating/displaying (editmode/editview) values
 * from the CMS page.content array
 * Do not use this for other purposes

 *
 * @param {Object} props
 * @param {String} props.type eg. type="submit" if you want to submit a form with cmsButton
 * @param {Function} props.onClick onClick to use as Button
 * @param {String} props.url url has priority and makes cmsButton a Link
 * @param {String} props.text button text
 * @param {String} props.styleType (primary | primär | 1) || (secondary | sekundär | 2)
 * @param {string} props.popoverPlacement should the popover appear top, bottom, left, rigth, defaults to top
 * @returns
 */
const PbContentButton = (props: PbContentButtonProps) => {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  return (
    <>
      {editView && !props.disableEditView ? (
        <CmsContentButton {...props} />
      ) : (
        <>
          {(props.text && (props.url || props.onClick)) ||
          props.type === "submit" ? (
            <div
              className={clsx("mui-button-wrapper", props.className)}
              style={props.disabled ? { cursor: "not-allowed" } : {}}
            >
              {props.url && !isUrlDownloadUrl(props.url) && (
                <Link
                  href={createHref(
                    props.url,
                    props.anchorName ?? "",
                    props.getParams ?? ""
                  )}
                  hrefLang={props.hrefLang}
                  passHref
                  target={
                    props.target ? props.target : getTargetValue(props.url)
                  }
                  rel={props.rel}
                >
                  <Button
                    className={clsx(props.className, "mui-cms-button")}
                    disabled={!!props.disabled}
                  >
                    {props.text}
                  </Button>
                </Link>
              )}
              {props.url && isUrlDownloadUrl(props.url) && (
                <a
                  href={`${props.url}${props.getParams ? props.getParams : ""}`}
                  target={
                    props.target ? props.target : getTargetValue(props.url)
                  }
                  download
                  rel={props.rel}
                >
                  <Button
                    className={clsx(props.className, "mui-cms-button")}
                    disabled={!!props.disabled}
                  >
                    {props.text}
                  </Button>
                </a>
              )}
              {!props.url && props.onClick && !props.startIcon && (
                <Button
                  onClick={props.onClick}
                  type={props.type ?? "button"}
                  className={clsx(props.className, "mui-cms-button")}
                  disabled={!!props.disabled}
                >
                  {props.text}
                </Button>
              )}
              {!props.url && props.type === "submit" && (
                <Button
                  type={"submit"}
                  className={clsx(props.className, "mui-cms-button")}
                  disabled={!!props.disabled}
                >
                  {props.text}
                </Button>
              )}
              {props.onClick && props.startIcon && (
                <Button
                  className={clsx(props.className, "mui-cms-button")}
                  onClick={props.onClick}
                  disabled={!!props.disabled}
                  type={props.type ?? "button"}
                  startIcon={props.startIcon}
                >
                  {props.text}
                </Button>
              )}
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default PbContentButton;
