import { ContentElementQuickLinksStoreSetting } from "@/types/ceSettings/ceSettings";
import { QUICK_LINKS_CE_NAME } from "./quickLinksContentElement";

interface QuickLinksContentElementGlobalStyleProps {
  ceSettings?: ContentElementQuickLinksStoreSetting;
}

const QuickLinksContentElementGlobalStyle = (
  props: QuickLinksContentElementGlobalStyleProps
) => {
  return (
    <style jsx global>
      {`
        :global(.${QUICK_LINKS_CE_NAME}) {
        }
      `}
    </style>
  );
};

export default QuickLinksContentElementGlobalStyle;
