import CmsAddButton from "@/components/cms/cmsAddButton/cmsAddButton";
import PbContentButton from "@/components/content/pbContentButton/pbContentButton";
import PbContentHeading from "@/components/content/pbContentHeading/pbContentHeading";
import useCesStrButton from "@/hooks/useCesStrButton";
import useCesStrHeadline from "@/hooks/useCesStrHeadline";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useScopedClassName from "@/hooks/useScopedClassName";
import { showNestedContentModal } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementQuickLinksStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEQuickLinks } from "@/types/content-elements";
import { getPbContentScopedSelector } from "@/utils/util";
import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import QuickLinksContentElementGlobalStyle from "./quickLinksContentElementStyleGlobal";
import QuickLinksContentElementStyleScoped from "./quickLinksContentElementStyleScoped";
export interface QuickLinksContentElementProps {
  content: CEQuickLinks;
  position: number;
  ceSettings: ContentElementQuickLinksStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}
export const QUICK_LINKS_CE_NAME = "quickLinksCE";

const QuickLinksContentElement = (props: QuickLinksContentElementProps) => {
  const QUICK_LINKS_SCOPED_CLASS = useScopedClassName(
    QUICK_LINKS_CE_NAME,
    props.content,
    props.position
  );

  const tCms = useCmsTranslation();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const dispatch = useAppDispatch();

  const { buttonClassName } = useCesStrButton(
    props.content.cfgStrInnerButtonButton,
    props.ceSettings?.button
  );

  const headlineSettings = useCesStrHeadline({
    typography: props.content.cfgStrInnerHeadlineTypography,
    font: props.content.cfgStrInnerHeadlineFont,
    fontColor: props.content.cfgStrInnerHeadlineFontColor,
    ceSettingHeadline: props.ceSettings?.headlineheading,
    cfgHeadlineType: props.content.cfgHeadlineType,
    ceSettings: props.ceSettings?.headline,
  });
  return (
    <>
      <ContentElementWrapper
        ceSettings={props.ceSettings}
        scopedClassName={QUICK_LINKS_SCOPED_CLASS}
        name={QUICK_LINKS_CE_NAME}
        content={props.content}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div className={clsx(QUICK_LINKS_CE_NAME, QUICK_LINKS_SCOPED_CLASS)}>
          <div className="quick-links-container">
            <Col>
              <Row>
                <div className="buttons-with-info">
                  <div className="info-text">
                    <PbContentHeading
                      scopedSelector={getPbContentScopedSelector(
                        QUICK_LINKS_SCOPED_CLASS
                      )}
                      content={props.content.title}
                      cmsPosition={props.position}
                      cmsField="title"
                      settings={{
                        ...headlineSettings,
                      }}
                      cmsPlaceholder={tCms("title")}
                    />
                  </div>
                  {props.content.content.map((content, index) => (
                    <React.Fragment key={index}>
                      <div className="button-wrapper">
                        <PbContentButton
                          popoverPlacement={
                            props.position < 1 ? "right" : "top"
                          }
                          text={content.btnText}
                          url={content.btnUrl}
                          rel={content.btnRel}
                          target={content.btnTarget}
                          getParams={content.btnGetParams}
                          hrefLang={content.btnHrefLang}
                          anchorName={content.btnAnchorName}
                          className={buttonClassName}
                          cmsFieldText={`content[${index}].btnText`}
                          cmsFieldGetParams={`content[${index}].btnGetParams`}
                          cmsFieldRel={`content[${index}].btnRel`}
                          cmsFieldTarget={`content[${index}].btnTarget`}
                          cmsFieldHrefLang={`content[${index}].btnHrefLang`}
                          cmsFieldUrl={`content[${index}].btnUrl`}
                          cmsFieldAnchorName={`content[${index}].btnAnchorName`}
                          cmsPosition={props.position}
                          cmsPopoverOpenerId={`content${props.position}.btn`}
                        />
                      </div>
                    </React.Fragment>
                  ))}

                  {editView && (
                    <div>
                      <Col>
                        <div className="button-link-add">
                          <div className="pb-generic-nested-content-add">
                            <CmsAddButton
                              title={tCms("addNewBtn")}
                              text={tCms("addNewBtn")}
                              onClick={() => {
                                dispatch(
                                  showNestedContentModal(props.position)
                                );
                              }}
                              height={70}
                              maxWidth={200}
                            ></CmsAddButton>
                          </div>
                        </div>
                      </Col>
                    </div>
                  )}
                </div>
              </Row>
            </Col>
          </div>
        </div>
      </ContentElementWrapper>
      <QuickLinksContentElementGlobalStyle ceSettings={props.ceSettings} />
      <QuickLinksContentElementStyleScoped
        {...props}
        scopedSelector={QUICK_LINKS_SCOPED_CLASS}
      />
    </>
  );
};

export default QuickLinksContentElement;
